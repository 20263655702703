<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab href="#ativos">Ativos</v-tab>
      <v-tab href="#naoativos">Não ativos</v-tab>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="ativos">
          <div class="mt-4">
            <v-card-title v-if="canList">
              Filtro
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="motivoscancelamento" :search="search" sort-by="descricao" class="border">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Motivos de Cancelamento registrados</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon v-if="canList" title="Atualizar" medium @click="getInfo">mdi-refresh</v-icon>
                  <v-spacer></v-spacer>
                  <v-btn v-if="canCreate" color="primary" dark class="mb-2" @click="() => { dialog = true }">Novo Motivo de
                    Cancelamento</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="prepareDeleteItem(item)" title="Inativar Motivo">mdi-delete</v-icon>
              </template>
            </v-data-table>

          </div>
        </v-tab-item>
        <v-tab-item value="naoativos">
          <div class="mt-4">
            <v-card-title v-if="canList">
                Filtro
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa" single-line
                  hide-details></v-text-field>
              </v-card-title>
            <v-data-table :headers="headers" :items="motivosCancelamentoInativos" :search="search" sort-by="descricao" class="border">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Motivos de Cancelamento Inativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon v-if="canList" title="Atualizar" medium @click="getInfo">mdi-refresh</v-icon>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="prepareAtiveItem(item)" title="Ativar Motivo">mdi-undo-variant</v-icon>
              </template>
            </v-data-table>

          </div>

        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog v-model="dialog" max-width="500px">
      <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <validation-provider>
                    <v-text-field class="mb-4" v-model="editedItem.descricao" :rules="descricaoRules" label="Descrição"
                      name="descricao" hint="Motivo do Cancelamento" persistent-hint />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox v-model="editedItem.active" label="Ativo" class="ma-0 pa-0" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>

            <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogAtivarItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Cuidado!</h3>
            <p class="mt-8">Tem certeza que deseja ativar este registro?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="
            dialogAtivarItem = false
          itemAtivar = {}
            ">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="AtivarItem()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title>
          <v-card-text>
            <h3 class="transition-swing text-h3 mb--1 error--text">Aviso!</h3>
            <p class="mt-8">Tem certeza que deseja inativar este registro?</p>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="
            dialogDeleteItem = false
          itemDelete = {}
            ">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="deleteItem()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PatioApi from '@/services/patio/index'
import { BasicPermissions, Sequences } from '@/utils/permissions'


export default {

  data() {
    return {
      dialog: false,
      selectGate: [],
      descricaoRules: [(v) => !!v || 'Descrição obrigatória'],
      search: '',
      headers: [
        {
          text: 'Ordem',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        gate: null,
        active: true,
        descricao: '',
        data: null,
      },
      defaultItem: {
        gate: null,
        active: true,
        descricao: '',
        data: null,
      },
      dialogDeleteItem: false,
      itemDelete: {},
      dialogAtivarItem: false,
      itemAtivar: {},
      tabs: null,
    }
  },
  computed: {
    ...mapState('patio', ['motivoscancelamento', 'motivosCancelamentoInativos']),
    ...mapState('auth', ['empresaAtual']),
    formTitle() {
      return this.editedIndex === -1 ? 'Novo Motivo de Cancelamento' : 'Editar Motivo de Cancelamento'
    },

    permission() {
      return Sequences.motivoCancelamento.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  async created() {
    if (this.canList) {
      this.getInfo()
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    ...mapActions('patio', ['getMotivosCancelamento', 'addMotivoCancelamento', 'editMotivoCancelamento', 'desativarMotivoCancelamento','ativarMotivoCancelamento', 'getMotivosInativos',]),


    prepareDeleteItem(item) {
      this.dialogDeleteItem = true
      this.itemDelete = item
    },

    prepareAtiveItem(item){
      this.dialogAtivarItem = true
      this.itemAtivar = item
    },

    adicionarMotivo() {
      this.$emit('showForm')
    },

    editItem(item) {
      this.editedIndex = this.MotivosCancelamento.indexOf(item)
      Object.assign(this.editedItem, item)
      this.dialog = true
    },

    async getInfo() {
      await this.getMotivosCancelamento({
        proprietario: this.empresaAtual.public_id,
      })
      await this.getMotivosInativos({
        proprietario: this.empresaAtual.public_id,
      })
    },

    async deleteItem() {
      await PatioApi.desativarMotivoCancelamento(this.itemDelete.id)
      this.dialogDeleteItem = false
      this.perifericoDelete = {}
      this.getInfo()
    },

    async AtivarItem(){
      await PatioApi.ativarMotivoCancelamento(this.itemAtivar.id)
      this.dialogAtivarItem = false
      this.getInfo()
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        Object.assign(this.editedItem, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save() {
      if (this.editedItem.descricao != null && this.editedItem.descricao.trim() != '') {
        this.editedItem.data = new Date(Date.now())
        this.editedItem.gate = this.empresaAtual.public_id
        this.addMotivoCancelamento(this.editedItem)
        this.dialog = false
        setTimeout(() => {
          this.getMotivosCancelamento({
            proprietario: this.empresaAtual.public_id,
          })
        }, 300)
      }
    },
  },
}
</script>

